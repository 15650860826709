import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/App";
import MainLayout from "./components/layout/MainLayout";
import About from "./components/page/about/About";
import BlogSidebar from "./components/page/blog/BlogSidebar";
import BlogDetails from "./components/page/BlogDetails/BlogDetails";
import BlogStandard from "./components/page/BlogStandard/BlogStandard";
import Contact from "./components/page/contact/Contact";
import ErrorPage from "./components/page/error/ErrorPage";
import Faq from "./components/page/faq/Faq";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Pricing from "./components/page/pricing/Pricing";
import Project from "./components/page/project/Project";
import ProjectDetails from "./components/page/ProjectDetails/ProjectDetails";
import Service from "./components/page/service/Service";
import ServiceDetails from "./components/page/ServiceDetails/ServiceDetails";
import Team from "./components/page/team/Team";
import "./index.css";

function Root() {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route exact path="/" component={MainLayout} />
        <Layout>
          <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
          <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
          <Route exact path={`${process.env.PUBLIC_URL}/error`} component={ErrorPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={BlogSidebar} />
          <Route exact path={`${process.env.PUBLIC_URL}/blog-standard`} component={BlogStandard} />
          <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails} />
          <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service} />
          <Route exact path={`${process.env.PUBLIC_URL}/service-details/:serviceType`} component={ServiceDetails} />
          <Route exact path={`${process.env.PUBLIC_URL}/project-details/:industryType`} component={ProjectDetails} />
          <Route exact path={`${process.env.PUBLIC_URL}/project`} component={Project} />
          <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team} />
          <Route exact path={`${process.env.PUBLIC_URL}/pricing`} component={Pricing} />
          <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
        </Layout>
      </Switch>
    </BrowserRouter>
  );
}

export default Root;

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
