import React, { useState } from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const openModal = (type) => {
    if (type === "terms") setShowTerms(true);
    if (type === "privacy") setShowPrivacy(true);
    setIsAccepted(false);
  };

  const closeModal = () => {
    if (isAccepted) {
      setShowTerms(false);
      setShowPrivacy(false);
    }
  };

  const handleAccept = () => {
    setIsAccepted(true);
    closeModal();
  };

  return (
    <>
      <footer className={props.footerAddclass}>
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link scrollTo={scrollTop} to={"/"}>
                      <img src={process.env.PUBLIC_URL + "/img/logo.png"} alt="FooterImg" />
                    </Link>
                  </div>
                  <p>
                    <h3>Shuvira Pte Ltd.</h3>
                    A dynamic and forward-thinking team specializing in sourcing and procurement of finished products and components.
                  </p>
                  <ul className="social-media-icons">
                    <li>
                      <a href="https://www.facebook.com/">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com/">
                        <i className="fab fa-pinterest-p" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Our Services</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/project-details/electronics`} onClick={scrollTop}>Strategy &amp; Electronics</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/project-details/technology`} onClick={scrollTop}>Technology</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/project-details/consumer-goods`} onClick={scrollTop}>Consumer Goods</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/project-details/green-energy`} onClick={scrollTop}>Green Energy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Quick Links</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/about`} onClick={scrollTop}>About Us</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/service`} onClick={scrollTop}>Expertise</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/project`} onClick={scrollTop}>Industries</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Contacts</h4>
                  <div className="number">
                    <div className="num-icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="phone">
                      <a href="#">65-62413991</a>
                    </div>
                  </div>
                  <div className="office-mail">
                    <div className="mail-icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="email">
                      <a href="#">biz_admin@shuvira.com</a>
                    </div>
                  </div>
                  <div className="address">
                    <div className="address-icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <p>Singapore.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt">
                  <span>
                    Copyright 2024 <b> Shuvira</b> | Design By{" Shambhavi Bangalore"}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  <li>
                    <a href="#" onClick={() => openModal("privacy")}>Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#" onClick={() => openModal("terms")}>Terms of Use</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Terms of Use */}
        {showTerms && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <h2>TERMS OF WEBSITE USAGE</h2>
              <div className="modal-text">
                <p>LAST REVISION: 01-September-2024</p>
                <p>
                  PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
                </p>
                <p>
                  This Terms of Service Agreement (the "Agreement") governs your use of this website, [Https://www.shuvira.com] (the "Website"), SHUVIRA PTE. LTD. ("SHUVIRA PTE. LTD.") offer of products for purchase on this Website, or your purchase of products available on this Website. This Agreement includes, and incorporates by this reference, the policies and guidelines referenced below. 
                  SHUVIRA PTE. LTD. reserves the right to change or revise the terms and conditions of this Agreement at any time by posting any changes or a revised Agreement on this Website. SHUVIRA PTE. LTD. will alert you that changes or revisions have been made by indicating on the top of this Agreement the date it was last revised. The changed or revised Agreement will be effective immediately after it is posted on this Website. Your use of the Website following the posting any such changes or of a revised Agreement will constitute your acceptance of any such changes or revisions. 
                  SHUVIRA PTE. LTD. encourages you to review this Agreement whenever you visit the Website to make sure that you understand the terms and conditions governing use of the Website. This Agreement does not alter in any way the terms or conditions of any other written agreement you may have with SHUVIRA PTE. LTD. for other products or services. If you do not agree to this Agreement (including any referenced policies or guidelines), 
                  please immediately terminate your use of the Website. If you would like to print this Agreement, please click the print button on your browser toolbar.
                </p>
                <p>
                  <strong>I. PRODUCTS</strong>
                  <br/>
                  Terms of Offer: This Website offers for sale certain products (the "Products"). By placing an order for Products through this Website, you agree to the terms set forth in this Agreement.
                </p>
                <p>
                  <strong>Customer Solicitation:</strong> Unless you notify our third-party call center reps or direct SHUVIRA PTE. LTD. sales reps, while they are calling you, of your desire to opt out from further direct company communications and solicitations, you are agreeing to continue to receive further emails and call solicitations SHUVIRA PTE. LTD. and its designated in-house or third party call team(s).
                </p>
                <p>
                  <strong>Opt Out Procedure:</strong> We provide 3 easy ways to opt out of from future solicitations. 1. You may use the opt out link found in any email solicitation that you may receive. 2. You may also choose to opt out, via sending your email address to: [biz_info@shuvira.com]. 3. You may send a written remove request to [address].
                </p>
                <p>
                  <strong>Proprietary Rights:</strong> SHUVIRA PTE. LTD. has proprietary rights and trade secrets in the Products. You may not copy, reproduce, resell or redistribute any Product manufactured and/or distributed by SHUVIRA PTE. LTD. <br></br>SHUVIRA PTE. LTD. also has rights to all trademarks and trade dress and specific layouts of this webpage, including calls to action, text placement, images and other information.
                </p>
                <p>
                  <strong>Sales Tax:</strong> If you purchase any Products, you will be responsible for paying any applicable sales tax.
                </p>
                <p>
                  <strong>II. WEBSITE</strong>
                  <br/>
                  Content; Intellectual Property; Third Party Links: In addition to making Products available, this Website also offers information and marketing materials. This Website also offers information, both directly and through indirect links to third-party websites. SHUVIRA PTE. LTD. does not always create the information offered on this Website; instead the information is often gathered from other sources.
                  <br></br>To the extent that SHUVIRA PTE. LTD. does create the content on this Website, such content is protected by intellectual property laws of the India, foreign nations, and international bodies. Unauthorized use of the material may violate copyright, trademark, and/or other laws. You acknowledge that your use of the content on this Website is for personal, noncommercial use. Any links to third-party websites are provided solely as a convenience to you. 
                  SHUVIRA PTE. LTD. does not endorse the contents on any such third-party websites. SHUVIRA PTE. LTD.  is not responsible for the content of or any damage that may result from your access to or reliance on these third-party websites. If you link to third-party websites, you do so at your own risk. 
                </p>
                <p>
                  <strong>Use of Website:</strong> <br></br>SHUVIRA PTE. LTD. is not responsible for any damages resulting from use of this website by anyone. You will not use the Website for illegal purposes. <br></br>You will (1) abide by all applicable local, state, national, and international laws and regulations in your use of the Website (including laws regarding intellectual property),<br></br>
                  (2) not interfere with or disrupt the use and enjoyment of the Website by other users, <br></br>(3) not resell material on the Website, <br></br>
                  (4) not engage, directly or indirectly, in transmission of "spam", chain letters, junk mail or any other type of unsolicited communication,<br></br>
                  and (5) not defame, harass, abuse, or disrupt other users of the Website.
                </p>
                <p>
                  <strong>License:</strong> <br></br>By using this Website, you are granted a limited, non-exclusive, non-transferable right to use the content and materials on the Website in connection with your normal, noncommercial, use of the Website. You may not copy, reproduce, transmit, distribute, or create derivative works of such content or information without express written authorization from SHUVIRA PTE. LTD. or the applicable third party (if third party content is at issue).
                </p>
                <p>
                  <strong>Posting:</strong> <br></br>By posting, storing, or transmitting any content on the Website, you hereby grant SHUVIRA PTE. LTD. a perpetual, worldwide, non-exclusive, royalty-free, assignable, right and license to use, copy, display, perform, create derivative works from, distribute, have distributed, transmit and assign such content in any form, in all media now known or hereinafter created, anywhere in the world. <br></br>
                  SHUVIRA PTE. LTD. does not have the ability to control the nature of the user-generated content offered through the Website. You are solely responsible for your interactions with other users of the Website and any content you post. SHUVIRA PTE. LTD. is not liable for any damage or harm resulting from any posts by or interactions between users. SHUVIRA PTE. LTD. reserves the right, but has no obligation, to monitor interactions between and among users of the Website and to remove any content SHUVIRA PTE. LTD.  deems objectionable. 
                </p>
                <p>
                  <strong>III. DISCLAIMER OF WARRANTIES</strong>
                  <br/>
                  YOUR USE OF THIS WEBSITE AND/OR PRODUCTS ARE AT YOUR SOLE RISK. THE WEBSITE AND PRODUCTS ARE OFFERED ON AN "AS IS" AND "AS AVAILABLE" BASIS. SHUVIRA PTE. LTD. EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT WITH RESPECT TO THE PRODUCTS OR WEBSITE CONTENT, OR ANY RELIANCE UPON OR USE OF THE WEBSITE CONTENT OR PRODUCTS. ("PRODUCTS" INCLUDE TRIAL PRODUCTS.) <br></br>
                  <br></br>WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, SHUVIRA PTE. LTD.  MAKES NO WARRANTY: <br></br>
                  <br></br>THAT THE INFORMATION PROVIDED ON THIS WEBSITE IS ACCURATE, RELIABLE, COMPLETE, OR TIMELY.<br></br>
                  <br></br>THAT THE LINKS TO THIRD-PARTY WEBSITES ARE TO INFORMATION THAT IS ACCURATE, RELIABLE, COMPLETE, OR TIMELY.<br></br>
                  <br></br>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THIS WEBSITE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.<br></br>
                  <br></br>AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PRODUCTS OR THAT DEFECTS IN PRODUCTS WILL BE CORRECTED. <br></br>
                  <br></br>REGARDING ANY PRODUCTS PURCHASED OR OBTAINED THROUGH THE WEBSITE.<br></br>
                  <br></br>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.<br></br>
                </p>
                <p>
                  <strong>IV. LIMITATION OF LIABILITY</strong>
                  <br/>
                  SHUVIRA PTE. LTD. ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN LAW, IN EQUITY, OR OTHERWISE, WITH RESPECT TO THE WEBSITE CONTENT AND PRODUCTS AND/OR FOR ANY BREACH OF THIS AGREEMENT IS SOLELY LIMITED TO THE AMOUNT YOU PAID, LESS SHIPPING AND HANDLING, FOR PRODUCTS PURCHASED VIA THE WEBSITE.<br></br>
                  <br></br>SHUVIRA PTE. LTD. WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THIS AGREEMENT OR THE PRODUCTS IN ANY MANNER, INCLUDING LIABILITIES RESULTING FROM (1) THE USE OR THE INABILITY TO USE THE WEBSITE CONTENT OR PRODUCTS; (2) THE COST OF PROCURING SUBSTITUTE PRODUCTS OR CONTENT; (3) ANY PRODUCTS PURCHASED OR OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH THE WEBSITE; OR (4) ANY LOST PROFITS YOU ALLEGE.<br></br>
                  <br></br>SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                </p>
                <p>
                  <strong>V. INDEMNIFICATION</strong>
                  <br/>
                  You will release, indemnify, defend and hold harmless SHUVIRA PTE. LTD., and any of its contractors, agents, employees, officers, directors, shareholders, affiliates and assigns from all liabilities, claims, damages, costs and expenses, including reasonable attorneys' fees and expenses, of third parties relating to or arising out of <br></br>(1) this Agreement or the breach of your warranties, representations and obligations under this Agreement. <br></br>
                  (2) the Website content or your use of the Website content <br></br>
                  (3) the Products or your use of the Products (including Trial Products)<br></br>
                  (4) any intellectual property or other proprietary right of any person or entity<br></br>
                  (5) your violation of any provision of this Agreement<br></br>
                  or (6) any information or data you supplied to SHUVIRA PTE. LTD<br></br>
                  <br></br>When SHUVIRA PTE. LTD. is threatened with suit or sued by a third party, SHUVIRA PTE. LTD. may seek written assurances from you concerning your promise to indemnify SHUVIRA PTE. LTD.; your failure to provide such assurances may be considered by SHUVIRA PTE. LTD. to be a material breach of this Agreement. SHUVIRA PTE. LTD. will have the right to participate in any defense by you of a third-party claim related to your use of any of the Website content or Products, with counsel of SHUVIRA PTE. LTD. choice at its expense. 
                  SHUVIRA PTE. LTD. will reasonably cooperate in any defense by you of a third-party claim at your request and expense. You will have sole responsibility to defend SHUVIRA PTE. LTD. against any claim, but you must receive SHUVIRA PTE. LTD. prior written consent regarding any related settlement. The terms of this provision will survive any termination or cancellation of this Agreement or your use of the Website or Products.
                </p>
                <p>
                  <strong>VI. PRIVACY</strong>
                  <br/>
                  SHUVIRA PTE. LTD. believes strongly in protecting user privacy and providing you with notice of SHUVIRA PTE. LTD.'s use of data. Please refer to SHUVIRA PTE. LTD. privacy policy, incorporated by reference herein, that is posted on the Website.
                </p>
                <p>
                  <strong>VI. AGREEMENT TO BE BOUND</strong>
                  <br/>
                  By using this Website or ordering Products, you acknowledge that you have read and agree to be bound by this Agreement and all terms and conditions on this Website.
                </p>
                <p>
                  <strong>VIII. GENERAL</strong>
                  <br/>
                  Force Majeure: SHUVIRA PTE. LTD. will not be deemed in default hereunder or held responsible for any cessation, interruption or delay in the performance of its obligations hereunder due to earthquake, flood, fire, storm, natural disaster, act of God, war, terrorism, armed conflict, labor strike, lockout, or boycott.<br></br>
                  <br></br>Cessation of Operation. SHUVIRA PTE. LTD. may at any time, in its sole discretion and without advance notice to you, cease operation of the Website and distribution of the Products.<br></br>
                  <br></br>Entire Agreement. This Agreement comprises the entire agreement between you and SHUVIRA PTE. LTD. and supersedes any prior agreements pertaining to the subject matter contained herein.<br></br>
                  <br></br>Effect of Waiver. The failure of SHUVIRA PTE. LTD. to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect.<br></br>
                  <br></br>Governing Law; [SINGAPORE]. This Website originates from the [SINGAPORE]. This Agreement will be governed by the laws of the State of [SINGAPORE] without regard to its conflict of law principles to the contrary. 
                  Neither you nor SHUVIRA PTE. LTD. will commence or prosecute any suit, proceeding or claim to enforce the provisions of this Agreement, to recover damages for breach of or default of this Agreement, or otherwise arising under or by reason of this Agreement, other than in courts located in State of [SINGAPORE]. 
                  By using this Website or ordering Products, you consent to the jurisdiction and venue of such courts in connection with any action, suit, proceeding or claim arising under or by reason of this Agreement. You hereby waive any right to trial by jury arising out of this Agreement and any related documents.<br></br>
                  <br></br>Statute of Limitation. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Website or Products or this Agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred.<br></br>
                  <br></br>Waiver of Class Action Rights. BY ENTERING INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN CLAIMS WITH THOSE OF OTHER IN THE FORM OF A CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING OUT OF, RELATING TO, OR CONNECTION WITH THIS AGREEMENT MUST BE ASSERTED INDIVIDUALLY.<br></br>
                  <br></br>Termination. SHUVIRA PTE. LTD. reserves the right to terminate your access to the Website if it reasonably believes, in its sole discretion, that you have breached any of the terms and conditions of this Agreement. Following termination, you will not be permitted to use the Website and SHUVIRA PTE. LTD. may, in its sole discretion and without advance notice to you, cancel any outstanding orders for Products. 
                  If your access to the Website is terminated, SHUVIRA PTE. LTD. reserves the right to exercise whatever means it deems necessary to prevent unauthorized access of the Website. This Agreement will survive indefinitely unless and until SHUVIRA PTE. LTD. chooses, in its sole discretion and without advance to you, to terminate it.<br></br>
                  <br></br>Domestic Use. SHUVIRA PTE. LTD. makes no representation that the Website or Products are appropriate or available for use in locations outside India. Users who access the Website from outside India do so at their own risk and initiative and must bear all responsibility for compliance with any applicable local laws.
                  Assignment. You may not assign your rights and obligations under this Agreement to anyone. SHUVIRA PTE. LTD. may assign its rights and obligations under this Agreement in its sole discretion and without advance notice to you.<br></br>
                  <br></br>BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
                </p>
                {/* Add more sections if needed */}
              </div>
              <button onClick={handleAccept} className="accept-button">
                Accept
              </button>
            </div>
          </div>
        )}

        {/* Modal for Privacy Policy */}
        {showPrivacy && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <h2>PRIVACY POLICY</h2>
              <div className="modal-text">
                <p>
                  Protection of Personal Data/Personally Identifiable Information (PII) is important to Shuvira Pte Ltd. We adopt reasonable and appropriate security practices and procedures including administrative, physical, and technical controls to safeguard Personal Information/Personally Identifiable Information,
                  We have implemented safeguards including organizational, technical, and physical measures to protect against accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, the personal data/ Personally Identifiable Information, we possess, process or use.
                </p>
                <p>
                  Shuvira.com has established this Privacy Policy to protect and control the collection, processing, storage, and/or transmission of such data. This policy is intended to be shared with our customers, vendors, associates, and staff so that they are aware of the policies and practices with respect to Personal Data/Personally Identifiable Information managed by Shuvira.com while delivering products and services. 
                  We urge you to read about our data privacy and information security policy on a periodic basis, because our privacy terms may change without notice anytime, and it is important that you are aware of any changes that might influence your actions and decisions while doing business with Shuvira.com.
                </p>
                <p>
                  <strong>Collecting Personally Identifiable Information (PII):</strong> At Shuvira.com, we identify, collect, process, store and transmit personally identifiable information (personal information) from customers when they set up an account with us, or when they buy from us, or at the time service is rendered from Shuvira.com to its customers.<br></br>
                  <br></br>1.	Full Names <br></br>
                           2. Addresses <br></br>
                           3.	Phone Numbers<br></br>
                           4.	Email Addresses<br></br>
                           5.	Designation<br></br>
                           6.	Company Website<br></br>
                           7.	Company / Business Name<br></br>
                           8.	Message<br></br>
                </p>
                <p>
                  <strong>Children’s Personal Information:</strong> We do not knowingly collect personal data/Personally Identifiable Information from children (persons who can be defined as children as per applicable laws). If the parents or guardians believe that their child/ward has provided us with personal data without their consent, such parents or guardians can contact us at biz_info@shuvira.com and we will take steps to delete such personal data/Personally Identifiable Information from our systems.
                </p>
                <p>
                  <strong>Using Personal Data/Personally Identifiable Information:</strong> Most of your actions such as browsing our website, viewing product / service / activity information, blogs, etc. may be conducted without registration. However, activities such as enrolling, purchasing, customer service, and referring to other prospective customers or interested parties require you to register on Shuvira.com.<br></br>
                  <br></br>The Personally Identifiable Information may be used for the following purposes:<br></br>
                  <br></br>1.	Deliver products and services.
                  <br></br>2.	Provide services such as responses to product queries, handling returns, processing of orders, contacting for providing clarifications, updates, newsletters, and offers.<br></br>
                  <br></br>Shuvira.com uses personally identifiable information to provide information about its products and services.  If the parties who receive such information wish to stop receiving further content, then the provision to “Opt-out” can be made use of. 
                  Personally Identifiable Information is used to provide the services customers have asked for.  Some such services include:<br></br>
                  <br></br>1.	Providing product information<br></br>
                           2.	Resolve complaints, escalations, and disputes.<br></br>
                           3.	Resolve problems and queries.<br></br>
                           4.	Promote our other products and services and for the ongoing improvement of our products and services.<br></br>
                           5.	Provide Updates<br></br>
                           6.	Quantify or measure customer feedback to customize or improve their experience.<br></br>
                           7.	Identify and remediate errors or defects and minimize such occurrences.<br></br>
                           8.	Identify, correct, and prevent fraudulent activities, and unlawful activities.<br></br>
                           9.	Enforce our business policies, terms, and conditions.<br></br>
                           10.	Collect payments/outstanding monies.<br></br>
                  <br></br><strong>Note:</strong> Content produced by end-users from reviews, comments, blogs, messaging services, forums, social media, and endorsements are not covered by this policy, as Shuvira.com is not responsible for the personal information generated due to activities by end-users.
                </p>
                <p>
                  <strong>Processing Demographic Data:</strong> Demographic data is identified, collected, and processed to profile user activities and business interactions with Shuvira.com via its websites.The IP addresses of users will be identified for purposes such as diagnosing and troubleshooting server and connectivity problems, and for website administration.<br></br>
                  <br></br>We will sometimes ask you to complete surveys (not compulsory).  Such surveys may request you for contact information and some basic personal information such as location, gender, age-range, and income status).  Such data will be utilized to customize your experience with Shuvira.com. To this effect, we may send content that we presume may be of interest and use to you or to render information to suit your preferences.<br></br>
                  <br></br>
                  <strong>Consequences of Not Providing Personal Data/PII/PHI: </strong>
                  If an individual chooses not to provide their personal information that is mandatory to process a request, service, or order then, Shuvira.com may not be able to execute the same or maybe restricted from completing satisfactorily.<br></br>
                  <br></br>
                  <strong>Using Cookies and Other Technologies: </strong>We use various technologies to collect information on Shuvira.com websites.<br></br>
                  <br></br><strong>Cookies: </strong>When an individual visits an Shuvira.com website, we may assign the computer one or more “cookies.” A cookie is a small text file that contains information that can later be read by Shuvira.com to facilitate access to the site and personalize the online experience. For example, when an individual signs into an Shuvira.com site, we may record his/her user ID in a cookie file on the individual’s computer. 
                  In addition, through the use of a cookie, we may automatically collect information about the online activity on an Shuvira.com site, such as the web pages visited, the links clicked, and the searches conducted. <br></br>
                  Most browsers automatically accept cookies; however, an individual can usually modify the browser setting to decline cookies by visiting the Help section of the browser’s toolbar. If an individual chooses to decline cookies, please note that he/she may not be able to sign in or use some of the interactive features offered on Shuvira.com websites.<br></br>
                  <br></br><strong>Other technologies: </strong>Shuvira.com may use standard Internet technology such as Web beacons (also called clear GIFs or pixel tags) and similar technologies, to deliver or communicate with cookies and track usage of its sites. We may also include Web beacons in e-mail messages or newsletters to determine whether messages have been opened and acted upon. <br></br>
                  The information we obtain in this manner enables us to customize the services we offer and measure the overall effectiveness of our online content, advertising campaigns, and the products and services offered through the website. Also, we use cookies to provide social media features and to analyze our traffic.
                </p>
                <p>
                  <strong>SHARING OF PERSONAL INFORMATION:</strong> We share Personal Data/Personally Identifiable Information (as per business needs) with: Shuvira.com Employees (for internal purposes).<br></br>
                  <br></br>Third parties and affiliates Purposes for sharing personal information/Personally Identifiable Information with third parties:<br></br>
                  <br></br>1.	To provide us with information security services to help identify, remediate, and prevent criminal activities
                  <br></br>2.	To minimize abuse of our product and service offerings.
                  <br></br>3.	To ensure compliance with regulatory or legal demands within reason. Personal Data/Personally Identifiable Information may have to be shared with enforcement authorities if required.
                  <br></br>4.	To apply our business terms or Privacy Policy; To act upon assertions on our commercial efforts, or any content from Shuvira.com stating the breach of the rights of any party, or to protect the rights, property or safety of our customers and associates.
                  <br></br>5.	To enable processing of data by third parties on behalf of Shuvira.com for co-branding of products or services, marketing, analytical and promotional purposes<br></br>
                  <br></br>Third parties that process Personal Information/Personally Identifiable Information on behalf of Shuvira.com for marketing, analytical and promotional purposes shall be bound by our privacy policy and will only act upon such data where the users would have given their explicit consent by opting-in and thereby positively affirming their acceptance to render their personal data/personally identifiable information for such purposes.  
                  Shuvira.com protects the confidentiality, availability, and integrity of subscriber information. Such information shall be used only for the purposes stated at the time of collection and will not be rented, sold, or provided to external parties for any other purposes than what has been stated.
                </p>
                <p>
                  <strong>LINKS TO OTHER EXTERNAL SITES:</strong> Shuvira.com websites may lead to other external websites that may collect your personal data/ personally identifiable information.<br></br>
                  <br></br>Shuvira.com claims no responsibility to the content, privacy practices or policies of such websites. <strong>Corrections regarding Personal Data/personally identifiable information & OPT-OUT.</strong><br></br>
                  <br></br>Users can request us to correct or delete personal data/Personally Identifiable Information that we hold, including profile and preferences.  If you are no longer interested in receiving updates or other marketing information from us, or if you want us to remove any Personal Information/Personally Identifiable Information that we have collected about you, then please e-mail your requests to <strong>biz_info@shuvira.com</strong>. We take up to 30 days to process your request and confirm the status of the action requested.
                </p>
                {/* Add more sections if needed */}
              </div>
              <button onClick={handleAccept} className="accept-button">
                Accept
              </button>
            </div>
          </div>
        )}
      </footer>

      {/* Inline CSS for the modal */}
      <style jsx>{`
        .modal {
          display: flex;
          justify-content: center;
          align-items: center;
          position: fixed;
          z-index: 1000;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
          background-color: rgba(0, 0, 0, 0.4);
        }

        .modal-content {
          background-color: #fefefe;
          margin: 15% auto;
          padding: 20px;
          border: 1px solid #888;
          width: 80%;
          max-width: 800px;
          max-height: 80vh;
          overflow-y: auto;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          animation: modalopen 0.5s;
        }

        @keyframes modalopen {
          from {
            opacity: 0;
            transform: translateY(-50px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .modal-text {
          line-height: 1.6;
          margin-bottom: 20px;
        }

        .close {
          color: #aaa;
          float: right;
          font-size: 28px;
          font-weight: bold;
          cursor: pointer;
        }

        .close:hover,
        .close:focus {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }

        h2 {
          margin-top: 0;
        }

        p {
          line-height: 1.6;
          margin-bottom: 15px;
        }

        .accept-button {
          display: inline-block;
          margin-top: 20px;
          padding: 10px 20px;
          font-size: 16px;
          color: white;
          background-color: rgb(52, 92, 132);
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .accept-button:hover {
          background-color: rgb(52, 92, 132);
        }
      `}</style>
    </>
  );
}

export default Footer;
