import React, { useState } from 'react';
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutArea(props) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="about-area sec-mar-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 or-2 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className={`${"sec-title layout2"} ${props.white}`}>
                <h2>ABOUT US</h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <h3>One stop partner for sourcing &amp; navigating the complex world of global supply chain.</h3>
                <p>Our expertise spans a diverse array of industries, and we are committed to deliver just the product that you need. 
                   To facilitate our operations, we have <b>dedicated subcontract facilities and partnered with leading manufacturers across the globe.</b><br></br><br></br>
                   Our in-depth network and expertise <b>provide the support necessary to strategically diversify supply chain, mitigate risks, optimize costs, access new markets, enhance flexibility</b>, foray into new business sectors and grow the brand presence.
                </p>
                <div className={`${"company-since"} ${props.layoutClass}`}>
                  <h4>We are a dynamic company with deep expertise in product & component sourcing, OEM manufacturing and end-to-end supply chain management, <b style={{ color: '#75DAB4' }}>Headquartered in Singapore.</b>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 or-1 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="about-right">
                <div className="banner-1">
                  <img src={process.env.PUBLIC_URL + '/img/knowus (1).png'} alt="about-images" />
                </div>
                <div className="banner-2">
                  <img src={process.env.PUBLIC_URL + '/img/knowus (2).png'} alt="about-images" />
                  <div className="banner2-inner">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>  
      
    </>
  );
}

export default AboutArea;
