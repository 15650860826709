import React, { useReducer, useState } from "react";
import { Link } from "react-router-dom";
import data from "../../../data/data";


function ProjectCardFilter() {
  const [items, setItem] = useState(data);

  const filterItem = (categoryItem) => {
    const updateItem = data.filter((currentElement) => {
      return currentElement.category === categoryItem;
    });

    setItem(updateItem);
  };

  const initialState = { statementActive: "" };
  function reducer(state, action) {
    switch (action.type) {
      case "dataAll":
        setItem(data);
        return { statementActive: "dataAll" };
      case "Electronics":
        filterItem("Electronics");
        return { statementActive: "Electronics" };
      case "Technology":
        filterItem("Technology");
        return { statementActive: "Technology" };
      case "Consumer Goods":
        filterItem("Consumer Goods");
        return { statementActive: "Consumer Goods" };
      case "Green Energy":
        filterItem("Green Energy");
        return { statementActive: "Green Energy" };
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <div className="container" style={{ maxWidth: "1000px", margin: "0 auto" }}>
        <div className="row project-items d-flex justify-content-center">
          {items.map((element) => {
            const { title, image, id, details, category } = element;
            return (
              <div 
                key={id} 
                className="col-md-6 col-lg-6 single-item"
                style={{ marginBottom: "30px", paddingLeft: "17px", paddingRight: "17px" }} // Added padding and margin for spacing
              >
                <div className="item-img">
                  <Link to={`${process.env.PUBLIC_URL}/project-details/${category.toLowerCase().replace(/ /g, '-')}`}>
                    <img src={image} alt="images" />
                  </Link>
                </div>
                <div className="item-inner-cnt">
                  <span>{title}</span>
                  <h4 style={{ whiteSpace: "pre-wrap" }}>
                   {details.split("|").join("  |  ")}
                  </h4>
                  <div className="view-btn">
                    <Link to={`${process.env.PUBLIC_URL}/project-details/${category.toLowerCase().replace(/ /g, '-')}`}>
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ProjectCardFilter;
