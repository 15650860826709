const data = [
    {
        id:1,
        title:"ELECTRONICS",
        image:"img/electronics.png",
        category: "Electronics",
        details:"Computing | Peripherals | Surveillance | Networking",
    },
    {
        id:2,
        title:"TECHNOLOGY",
        image:"img/technology.png",
        category: "Technology",
        details:"Medical-Tech | Edu-Tech | Aero Space",
    },
    {
        id:3,
        title:"CONSUMER GOODS",
        image:"img/consumer-goods.png",
        category: "Consumer Goods",
        details:"Display | Audio | Stationary",
    },
    {
        id:4,
        title:"GREEN ENERGY",
        image:"img/green-energy.png",
        category: "Green Energy",
        details:"Solar Solutions | Equipment & Vehicles",
    },

];

export default data;