import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import WOW from "wowjs";

function Header() {
  const [sidebar, setSidebar] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);

  // This function will handle the sticky header logic
  const handleStickyHeader = () => {
    if (ref.current) {
      // Only set the header as sticky if the scroll position is greater than a threshold
      setSticky(window.scrollY > 50);
    }
  };

  // Toggle sidebar
  const handleSidbarMenu = () => {
    setSidebar(!sidebar);
  };

  // Close sidebar when an option is clicked
  const handleMenuOptionClick = () => {
    setSidebar(false); // Ensure the sidebar closes after an item is clicked
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  // Add scroll event listener for sticky header logic
  useEffect(() => {
    new WOW.WOW({ live: false }).init();
    window.addEventListener("scroll", handleStickyHeader);
    return () => {
      window.removeEventListener("scroll", handleStickyHeader);
    };
  }, []);

  return (
    <>
      <header
        className={`header-area position_top ${isSticky ? "sticky" : ""}`}
        ref={ref}
      >
        <div className="site-logo">
          <div className="logo">
            <Link to={"/"} onClick={scrollTop} className="logo-link">
              <img
                src={process.env.PUBLIC_URL + "/img/logo.png"}
                alt="logo-img"
              />
            </Link>
          </div>
        </div>

        <div className="main-menu">
          <nav className={sidebar ? "main-nav slidenav open" : "main-nav"}>
            <div className="mobile-menu-logo">
              <Link to={"/"} onClick={scrollTop}>
                <img
                  src={process.env.PUBLIC_URL + "img/logo.png"}
                  alt="logo"
                />
              </Link>
              <div className="remove" onClick={handleSidbarMenu}>
                <i className="bi bi-plus-lg" />
              </div>
            </div>
            <ul>
              <li className="dropdown">
                <NavLink to={"/"} onClick={() => {scrollTop(); handleMenuOptionClick();}}>
                  Home
                </NavLink>
              </li>
              <li className="dropdown">
                <NavLink to={`${process.env.PUBLIC_URL}/about`} onClick={() => {scrollTop(); handleMenuOptionClick();}}>
                  About Us
                </NavLink>
              </li>
              <li className="dropdown">
                <NavLink to={`${process.env.PUBLIC_URL}/service`} onClick={() => {scrollTop(); handleMenuOptionClick();}}>
                  Expertise
                </NavLink>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/service-details/customisation`}
                      onClick={() => {scrollTop(); handleMenuOptionClick();}}
                    >
                      Customisation
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/service-details/global-partnerships`}
                      onClick={() => {scrollTop(); handleMenuOptionClick();}}
                    >
                      Global Partnerships
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/service-details/experience`}
                      onClick={() => {scrollTop(); handleMenuOptionClick();}}
                    >
                      Experience
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/service-details/end-to-end-management`}
                      onClick={() => {scrollTop(); handleMenuOptionClick();}}
                    >
                      End to End Management
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <NavLink to={`${process.env.PUBLIC_URL}/project`} onClick={() => {scrollTop(); handleMenuOptionClick();}}>
                  Industries
                </NavLink>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/project-details/electronics`}
                      onClick={() => {scrollTop(); handleMenuOptionClick();}}
                    >
                      Electronics
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/project-details/technology`}
                      onClick={() => {scrollTop(); handleMenuOptionClick();}}
                    >
                      Technology
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/project-details/consumer-goods`}
                      onClick={() => {scrollTop(); handleMenuOptionClick();}}
                    >
                      Consumer Goods
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/project-details/green-energy`}
                      onClick={() => {scrollTop(); handleMenuOptionClick();}}
                    >
                      Green Energy
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="get-qoute d-flex justify-content-center d-lg-none d-block pt-50">
              <div className="cmn-btn">
                <div className="line-1"></div>
                <div className="line-2"></div>
                <Link
                  to={`${process.env.PUBLIC_URL}/contact`}
                  onClick={() => {scrollTop(); handleMenuOptionClick();}}
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </nav>
        </div>

        <div className="nav-right">
          <div className="get-qoute">
            <div className="cmn-btn">
              <div className="line-1" />
              <div className="line-2" />
              <Link
                to={`${process.env.PUBLIC_URL}/contact`}
                onClick={scrollTop}
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="mobile-menu">
            <Link to="#" onClick={handleSidbarMenu} className="cross-btn">
              <span className="cross-top" />
              <span className="cross-middle" />
              <span className="cross-bottom" />
            </Link>
          </div>
        </div>
      </header>

      <style jsx="true">{`
        .header-area {
          z-index: 9999; /* Ensure the header is always on top */
        }

        .sticky {
          position: sticky;
          top: 0;
          background: #fff;
          z-index: 10000;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .slidenav.open {
          transform: translateX(0); /* Ensure the sidebar slides in fully */
        }

        /* Mobile Specific Styles */
        @media (max-width: 768px) {
          .header-area {
            height: 80px; /* Adjust the header height for mobile */
          }

          .site-logo img {
            width: auto !important; /* Allow the logo to scale width proportionally */
            height: 100px !important; /* Increase the height for mobile */
            max-height: none !important; /* Ensure no max-height is limiting it */
          }

          .main-menu .main-nav {
            top: 70px; /* Align the mobile menu after adjusting the header height */
          }

          .mobile-menu-logo img {
            width: auto !important; /* Adjust the width for mobile menu */
            height: 80px !important; /* Set height in the mobile menu */
            max-height: none !important; /* Ensure no max-height restriction */
          }

          .nav-right .get-qoute {
            margin-top: 10px; /* Adjust the spacing to fit smaller header */
          }
        }
      `}</style>
    </>
  );
}

export default Header;
