import React from 'react';

function ServiceDetailsItems({ serviceType }) {
  return (
    <>
      <div className="service-items sec-mar-top">
        {serviceType === 'customisation' && (
          <>
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                  <h4><span>01</span>ENGINEERING SUPPORT</h4>
                    <p>
                      <b>• For Concept to Product: </b>
                      We participate with you in your concept definition, understanding market and end user demand and enlisting the features of the end product.
                      <br></br>Product design will be done hand in hand with your team.
                      Identifying possible challenges and resolve them transparently
                      Finalise and mass production of the finished product
                      <br /><br />
                      <b>• For Product or Component Sourcing: </b>
                      We study the description, technical specification and delivery requirements for defined component demand. <p>Our team will offer the exact and the other potential options for the same for your evaluation and decision.</p>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src={process.env.PUBLIC_URL + '/img/engineering support.png'} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img src={process.env.PUBLIC_URL + '/img/service1.png'} alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4><span>02</span>FLEXIBLE PROCUREMENT</h4>
                    <p>Our adaptable process ensures we can procure specialized components, finished products, or entire assemblies to suit your needs.
                      <b>We focus on saving customers time and effort in identifying the apt suppliers or manufacturing partners.</b> <br></br><br></br>Our robust network and industry connections allow us to connect with the most suitable ones.
                      With minimal constraint on MOQ of products,<b> we offer solutions ensuring less inventory holding and capital deployment at your end.</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                  <h4><span>03</span>TAILORED SOLUTIONS</h4>
                    <p>We recognize the distinctive needs of your clients & projects, and our services are designed to meet those requirements.
                      Shuvira delivers products meticulously engineered to meet and exceed exacting specifications. <br></br><br></br>Our solutions are designed to align seamlessly with your expectation, ensuring satisfaction and optimal results.
                      <br /><br />
                      <b>• Project specific customised component design and sourcing. <br /></b>
                      <b>• Customisation of finished products to meet your brand value.</b>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src={process.env.PUBLIC_URL + '/img/service3.jpg'} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {serviceType === 'global-partnerships' && (
          <>
            

            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4><span>01</span>STRATEGIC ALLIANCES</h4>
                    <p><b>Diversifying the sourcing strategy is crucial to ensure business continuity and competitiveness. </b>
                      Shuvira partners with leading manufacturers in Asia, Europe, Middle East, Australia, and the USA.
                      Headquartered in Singapore, Shuvira has strategic partnerships with Asian leading manufacturers & dealers in Taiwan, China, Japan, Vietnam & India.<br></br><br></br> Premium manufacturers and brand alignments in Germany, Sweden, Middle East in addition to Australia & USA ensures the highest quality and innovation in every product we offer.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src={process.env.PUBLIC_URL + '/img/service2.png'} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img src={process.env.PUBLIC_URL + '/img/service-2.jpg'} alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4><span>02</span>QUALITY ASSURANCE</h4>
                    <p>We leverage our partner connections to provide competitive pricing, high-quality materials, and efficient delivery.
                      At Shuvira , <b>our team focusses on the quality standards maintained by the manufacturing partners.</b> This includes their quality control processes, adherence to industry standards and certifications, and the overall commitment to producing high-quality products. 
                      Reviewing their <b>on-time delivery performance ensures reliable adherence to delivery deadlines</b>, which is crucial for smooth business operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {serviceType === 'experience' && (
          <>
            

            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4><span>01</span>SEASONED PROFESSIONALS</h4>
                    <p>Our team, with over 25 years of specialized experience in sourcing and procurement, brings unparalleled industry expertise and deep insights.
                      We leverage this extensive knowledge to deliver strategic solutions and optimize supply chain efficiency, ensuring competitive advantage and value for our clients.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src={process.env.PUBLIC_URL + '/img/service3.png'} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img src={process.env.PUBLIC_URL + '/img/diverse.jpg'} alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4><span>02</span>DIVERSE INDUSTRY KNOWLEDGE</h4>
                    <p>Shuvira has a proven track record of successfully serving a diverse array of industries, including electronics, aero-space, consumer goods, and industrial components.
                      Our extensive experience across these sectors enables us to source & deliver products that address the unique challenges and requirements of each industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {serviceType === 'end-to-end-management' && (
          <>
            

            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4><span>01</span>Total Management</h4>
                    <p>From the initial order placement to the final delivery, <b>we oversee the complete procurement, production, shipping, and logistics processes. </b>
                      Our meticulous management ensures seamless coordination and execution at every stage, guaranteeing timely and precise fulfillment while maintaining the highest standards of quality and efficiency.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src={process.env.PUBLIC_URL + '/img/service4.jpg'} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img src={process.env.PUBLIC_URL + '/img/service-4.jpeg'} alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4><span>02</span>Risk Mitigation</h4>
                    <p><b>We implement comprehensive risk mitigation strategies by conducting rigorous supplier assessments and stringent quality checks. </b>
                      This proactive approach allows us to identify and address potential risks associated with procurement, ensuring suppliers meet our standards for reliability and performance. <br></br><br></br> Through these meticulous evaluations, we safeguard against disruptions and maintain the integrity of our supply chain. 
                      Our supply chain diversification service <b>aims to assist businesses in achieving their main goal of reducing supply chain risks and enhancing resilience.</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ServiceDetailsItems;
