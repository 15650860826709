import React from 'react';
import { useParams } from 'react-router-dom';

function ProjectDetailsContent() {
  const { industryType } = useParams();

  const industryDetails = {
    electronics: {
      title: "ELECTRONICS",
      description: `
        <h5 style="color: #75DAB4;line-height: 30px;">We offer a comprehensive range of high-quality solutions tailored to meet your specific needs. With Shuvira, you gain a reliable partner committed to delivering excellence in electronics.</h5>
        <br/>
      `,
      imageUrl: process.env.PUBLIC_URL + '/img/electronics.png',
      sections: {
        COMPUTING: {
          text: `
            <p>At Shuvira, we are dedicated to providing a wide array of technology products that cater to modern needs, including <strong>Laptops, Tablets, Desktop PCs, and All-in-One desktops.</strong></p><br/>
            <p>Our devices are designed to empower professionals to work from anywhere, with intelligent, secure systems that boost productivity on the move.</p><br/>
            <p>Our expertise lies in delivering a broad spectrum of computer peripherals, including <strong>Mainboards, Power Supply Units, PCB-based products, SSDs, Keyboards, Mice, and more.</strong> Be it individual components or fully assembled products, Shuvira delivers solutions customized to your exact specifications.</p><br/>
            <p><strong>Partner with Shuvira</strong> for tech solutions that keep you ahead of the curve.</p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/computing.png'
        },
        PERIPHERALS: {
          text: `
            <p>At Shuvira, we pride ourselves on being a leading supplier of essential components for EMS manufacturing companies.</p><br/>
            <p>Whether you're looking for specific components or fully customized solutions, Shuvira crafts offerings that are precisely tailored to your requirements.</p><br/>
            <p>We combine cutting-edge technology with personalized service to deliver high-quality products that meet the demands of today’s dynamic work environments.</p><br/>
            <p>With a focus on quality, precision, and reliability, we ensure that every product meets the highest industry standards, supporting your manufacturing processes with excellence.</p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/computing (2).png'
        },
        SURVEILLANCE: {
          text: `
            <p>Our expertise empowers OEMs and integrators with smart, innovative solutions tailored to specific needs in surveillance projects.</p><br/>
            <p>Our product range includes advanced cameras designed for <strong>thermal detection, face recognition, intrusion detection, crowd management, and more.</strong></p><br/>
            <p>We specialize in creating products for unique applications such as <strong>in-vehicle monitoring systems and door-access systems for building surveillance.</strong></p><br/>
            <p>Shuvira ensures that every solution is designed to meet your precise requirements, enhancing security and efficiency for any surveillance project.</p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/surveillance.png'
        },
        NETWORKING: {
          text: `
            <p>Shuvira offers customizable solutions using the latest technologies in networking products, including <strong>routers, switches, access points, and network security devices.</strong></p><br/>
            <p>We tailor our offerings to meet the unique needs of your business, ensuring seamless connectivity, enhanced security, and optimal performance.</p><br/>
            <p><strong>Partner with Shuvira</strong> for cutting-edge networking solutions designed to keep your operations running smoothly.</p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/networking.png'
        }
      }
    },
    technology: {
      title: "TECHNOLOGY",
      description: `
        <h5 style="color: #75DAB4; line-height: 30px;">Partner for expert support and high-quality smart technology innovations. Our offerings include smart sensors, IoT devices, advanced wearables, space and defence solutions.</h5>
        <br/>
      `,
      imageUrl: process.env.PUBLIC_URL + '/img/technology.png',
      sections: {
        AEROSPACE: {
          text: `
            <p>A comprehensive range of products for the Aerospace and Defence industry, including <strong>UAV power solutions, advanced gimbals, and components for high-end defence and space instruments.</strong></p><br/>
            <p>Our products are engineered to meet the rigorous demands of these critical sectors, offering reliability and performance.</p><br/>
            <p>Shuvira ensures access to cutting-edge technology and precision components that support sophisticated aerospace and defence applications.</p><br/>
            <p>We tailor our offerings to meet the unique needs of your business, <strong>ensuring seamless connectivity, enhanced security, and optimal performance.</strong></p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/aerospace.png'
        },
        EDUTECH: {
          text: `
            <p>A diverse range of products for the Education Technology industry, including <strong>Interactive Panels and Smart Classroom solutions.</strong></p><br/>
            <p>Our advanced products are designed to enhance learning experiences and foster interactive teaching environments.</p><br/>
            <p><strong>With Shuvira, you get high-quality, innovative technology that supports modern educational needs.</strong></p><br/>
            <p>Transform your educational spaces with our tailored solutions for a more engaging and effective learning process.</p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/edu-tech.png'
        },
        MEDICALTECH: {
          text: `
            <p>An extensive range of products for the Medical Technology industry, designed to enhance healthcare through innovation.</p><br/>
            <p>Our offerings include <strong>Health Monitoring Wearables, Temperature Smart Watches, IoT-enabled Wearables, and advanced medical instruments.</p><br/>
            <p>We focus on integrating the latest technology to provide accurate, real-time health data and improve patient care.</strong></p><br/>
            <p>Whether you need sophisticated wearables for continuous health monitoring or precise instruments for medical diagnostics, Shuvira provides tailored solutions that meet the highest standards of quality and reliability.</p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/medical-tech.png'
        }
      }
    },
    'consumer-goods': {
      title: "CONSUMER GOODS",
      description: `
        <h5 style="color: #75DAB4;line-height: 30px;">Shuvira offers a diverse range of Consumer Goods catering from enhancing home entertainment to supporting creative and organizational tasks. Whether you're looking for the latest in TVs and speakers or premium art supplies and office essentials, Shuvira provides top-notch products for end customers. Discover solutions that blend innovation, performance, and style.</h5>
        <br/>
      `,
      imageUrl: process.env.PUBLIC_URL + '/img/consumer-goods.png',
      sections: {
        AUDIO: {
          text: `
            <p>A diverse range of Consumer Audio Goods, including <strong>USB Speakers, Bluetooth Speakers, Headsets, and Professional Amplifiers.</strong></p><br/>
            <p>Our products are crafted to deliver superior sound quality and performance for various audio needs.</p><br/>
            <p>Whether for personal enjoyment or professional use, Shuvira provides innovative audio solutions that enhance your listening experience.</p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/audio.png'
        },
        DISPLAY: {
          text: `
            <p>An extensive selection for OEMs and Manufacturers in the consumer display segment including <strong>Televisions, Smart Android TVs, LED Monitors, and Panels.</strong></p><br/>
            <p>Our products are designed to deliver <strong>exceptional visual quality and performance for a range of applications.</strong></p><br/>
            <p>Whether you're looking to enhance home entertainment or upgrade business displays, Shuvira provides high-quality solutions that cater to diverse needs.</p><br/>
            <p>If you are manufacturing for your brand, we provide high-end BOM component support meeting your product specification.</p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/display.png'
        },
        STATIONERY: {
          text: `
            <p>Shuvira provides a wide array of stationery products catering to creative and organizational needs.</p><br/>
            <p><strong>An extensive selection of Bullet Journals, Premium Art Pens, Paints, and Accessories designed to inspire and elevate your work.</strong></p><br/>
            <p>Whether you're an artist, student, or professional, Shuvira offers high-quality items that enhance your stationery experience.</p><br/>
            <p>Discover our range for tools that blend functionality with creativity.</p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/stationery.png'
        }
      }
    },
    'green-energy': {
      title: "GREEN ENERGY",
      description: `
        <h5 style="color: #75DAB4;line-height: 30px;">Shuvira provides a wide range of products and components for manufacturers focusing on Green Energy Solutions. Our offerings include advanced Solar Solutions and cutting-edge EV Systems, designed to support sustainable energy innovations. Explore our solutions to enhance your green energy projects with reliability and efficiency.</h5>
        <br/>
      `,
      imageUrl: process.env.PUBLIC_URL + '/img/green-energy.png',
      sections: {
        SOLARSOLUTIONS: {
          text: `
            <p>Shuvira actively partners with OEMs and manufacturers to provide a wide range of Solar Solutions.</p><br/>
            <p>Our offerings include components and finished products for <strong>Solar Panels, PV Cells, Battery Backups, and Hydrogen Power Cells.</strong></p><br/>
            <p>Together, we deliver innovative and efficient products to advance sustainable energy solutions.</p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/solar.jpg'
        },
        EQUIPMENTVEHICLES: {
          text: `
            <p>We collaborate with OEMs and manufacturers to deliver innovative products and components for <strong>EV vehicles, construction equipment, and energy meters.</strong></p><br/>
            <p>Our collaborations ensure cutting-edge solutions tailored to meet the evolving demands of modern industries.</p><br/>
            <p>Join us in driving progress and excellence through strategic alliances and advanced technology.</p><br/>
          `,
          image: process.env.PUBLIC_URL + '/img/e&v.png'
        }
      }
    }
  };

  const content = industryDetails[industryType.toLowerCase().replace(/\s+/g, '-')] || {};

  const mainSectionStyles = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
  };

  const mainImageStyles = {
    flex: 1,
    maxWidth: '50%',
  };

  const mainTextStyles = {
    flex: 1,
    paddingLeft: '40px',
  };

  const sectionStyles = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px',
  };

  const textStyles = {
    flex: 1,
    paddingLeft: '40px',
  };

  const imageStyles = {
    flex: 1,
    maxWidth: '40%',
  };

  const reversedSectionStyles = {
    ...sectionStyles,
    flexDirection: 'row-reverse',
    textAlign: 'left',
    paddingRight: '40px', 
  };

  return (
    <div className="project-details-content">
      <div className="main-section" style={mainSectionStyles}>
        <div className="main-image" style={mainImageStyles}>
          <img src={content.imageUrl} alt={`${content.title} image`} style={{ width: '100%' }} />
        </div>
        <div className="main-text" style={mainTextStyles}>
          <h3 style={{ color: 'rgb(52, 92, 132)', fontSize: '3.5em' }}>{content.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: content.description }} />
        </div>
      </div>

      {content.sections && Object.keys(content.sections).map((sectionKey, index) => (
        <div
          key={index}
          className={`section ${index % 2 === 0 ? 'reversed-section' : ''}`}
          style={index % 2 === 0 ? reversedSectionStyles : sectionStyles}
        >
          <div className="section-image" style={imageStyles}>
            <img src={content.sections[sectionKey].image} alt={`${sectionKey} image`} style={{ width: '100%' }} />
          </div>
          <div className="section-text" style={textStyles}>
            <h4 style={{ textTransform: 'uppercase', color: '#75DAB4', fontSize: '2.5em' }}>{sectionKey}</h4>
            <div dangerouslySetInnerHTML={{ __html: content.sections[sectionKey].text }} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProjectDetailsContent;
