import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function HeroArea() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        {
            id: 1,
            tagline: "Precision Sourcing, Seamless Solutions.",
            description: "A dynamic and forward-thinking team specializing in sourcing and procurement of finished products and components.",
            imgSrc: process.env.PUBLIC_URL + "/img/home (1).png"
        },
        {
            id: 2,
            tagline: "Efficient Procurement, Flawless Execution",
            description: "Integrating advanced strategies with precise attention to detail, we ensure cost-effective and timely solutions. ",
            imgSrc: process.env.PUBLIC_URL + "/img/home2.jpg"
        }
    ];

    const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

    // Auto-slide effect
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 4000); // Auto-scroll every 4 seconds
        return () => clearInterval(interval);
    }, [slides.length]);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? slides.length - 1 : prevSlide - 1
        );
    };

    return (
        <>
            <section className="hero-area">
                <div className="slider-wrapper">
                    <div
                        className="slider"
                        style={{
                            transform: `translateX(-${currentSlide * 100}%)`,
                        }}
                    >
                        {slides.map((slide) => (
                            <div key={slide.id} className="slide">
                                <div className="row no-gutters full-height">
                                    <div className="col-lg-6 d-flex align-items-center justify-content-center slide-content">
                                        <div className="hero-content text-center">
                                            {slide.title && (
                                                <h3>{slide.title}</h3>
                                            )}
                                            {slide.tagline && (
                                                <h2>{slide.tagline}</h2>
                                            )}
                                            <p>{slide.description}</p>
                                            <div className="buttons">
                                                <div className="cmn-btn">
                                                    <div className="line-1" />
                                                    <div className="line-2" />
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/project`}
                                                        onClick={scrollTop}
                                                    >
                                                        Explore
                                                    </Link>
                                                </div>
                                                <div className="cmn-btn layout-two">
                                                    <div className="line-1" />
                                                    <div className="line-2" />
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/contact`}
                                                        onClick={scrollTop}
                                                    >
                                                        Contact Us
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 slide-image">
                                        <div className="ken-burns-slideshow">
                                            <img
                                                src={slide.imgSrc}
                                                alt="HeroBanner"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


            </section>

            <style jsx="true">{`
                .hero-area {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100vh;
                    overflow: hidden;
                }
                .slider-wrapper {
                    width: 100%;
                    overflow: hidden;
                }
                .slider {
                    display: flex;
                    transition: transform 0.5s ease-in-out;
                }
                .slide {
                    min-width: 100%;
                    box-sizing: border-box;
                }
                .full-height {
                    height: 100vh; /* Ensure full viewport height for each slide */
                }
                .slide-image {
                    height: 100%;
                    overflow: hidden;
                }
                .ken-burns-slideshow {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ken-burns-slideshow img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .slide-content {
                    padding: 20px;
                }
                .hero-content {
                    color: #fff;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
                }
                .hero-content h3,
                .hero-content h2 {
                    margin: 0 0 30px;
                }
                    .hero-content h3{
                       
                       font-size: 35px;
                    }
                .hero-content h2{
                       font-size: 40px;
                       fontWeight: bold;
                        color: #75DAB4;
                }
                .hero-content p {
                    margin-bottom: 20px;
                }
                .buttons .cmn-btn {
                    margin-right: 15px;
                    color: rgb(52, 92, 132);
                }
                .buttons .layout-two {
                    margin-left: 15px;
                }
                .arrow {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 2rem;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0.5);
                    padding: 10px;
                    cursor: pointer;
                    z-index: 10;
                }
                .left-arrow {
                    left: 10px;
                }
                .right-arrow {
                    right: 10px;
                }

                /* Add responsive styles for smaller screens */
                @media only screen and (max-width: 768px) {
                    .hero-area {
                        height: 70vh; /* Decrease the height for mobile */
                    }
                    .slide-image {
                        display: none; /* Hide images for better readability */
                    }
                    .hero-content {
                        text-align: center;
                    }
                    .hero-content h2 {
                        font-size: 24px; /* Smaller font for mobile */
                        padding-top: 50px;
                    }
                    .hero-content p {
                        font-size: 16px; /* Adjust description font */
                    }
                    .arrow {
                        font-size: 1.5rem; /* Reduce arrow size on mobile */
                    }
                }
            `}</style>
        </>
    );
}

export default HeroArea;
