import React from "react";
import { useParams } from "react-router-dom";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ServiceDetailsItems from "./ServiceDetailsItems";

function ServiceDetails() {
  const { serviceType } = useParams();

  // Function to format the serviceType for display
  const formatServiceType = (type) => {
    return type.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const formattedServiceType = formatServiceType(serviceType);

  // Map service type to image path
  const serviceImageMap = {
    customisation: process.env.PUBLIC_URL + "/img/Ourexpertisetopbanner.png",
    "global-partnerships": process.env.PUBLIC_URL + "/img/global.jpg",
    experience: process.env.PUBLIC_URL + "/img/experience.jpg",
    "end-to-end-management": process.env.PUBLIC_URL + "/img/endtoend.jpg",
  };

  const serviceImage = serviceImageMap[serviceType];

  // Inline styles for mobile view with consistent color and text
  const mobileStyles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      padding: "10px",
    },
    image: {
      width: "100%",
      height: "auto",
      objectFit: "cover",
      marginBottom: "15px",
    },
    text: {
      padding: "0 10px",
      fontSize: "1.2rem",
      lineHeight: "1.5",
      wordWrap: "break-word",
      color: "#75DAB4", // Same color as desktop view
      fontWeight: "bold", // Same bold styling
    },
    heading: {
      fontSize: "2rem",
      textAlign: "center",
      wordWrap: "break-word",
      marginBottom: "10px",
      color: "#345C84", // Same heading color as desktop view
      textTransform: "uppercase",
    },
  };

  // Inline styles for desktop view (unchanged from your original)
  const mainSectionStyles = {
    display: "flex",
    alignItems: "center",
    marginBottom: "0px",
  };

  const mainImageStyles = {
    flex: 1,
    maxWidth: "50%",
  };

  const mainTextStyles = {
    flex: 1,
    paddingLeft: "40px",
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <div className="creasoft-wrap">
        <AnimateLine />
        <Breadcrumb
          pageName={`Expertise - ${formattedServiceType}`}
          pageTitle={`Expertise - ${formattedServiceType}`}
          backgroundImage={process.env.PUBLIC_URL + "/img/ourexpertise.png"}
        />
        <div className="service-details sec-mar-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-content">
                  {isMobile ? (
                    <div style={mobileStyles.container}>
                      <img src={serviceImage} alt={formattedServiceType} style={mobileStyles.image} />
                      <h3 style={mobileStyles.heading}>{formattedServiceType}</h3>
                      <p style={mobileStyles.text}>
                        {serviceType === "customisation" &&
                          "Whether you are sourcing a new customised product for your business or project, or components for your regular manufacturing or business, Shuvira team ensures solutions meeting your specific product requirements and key criteria."}
                        {serviceType === "global-partnerships" &&
                          "Our global network provides strong partners and potential alternatives for a strong supply chain management solution. At Shuvira, we aim to empower your business with global market knowledge, expertise, and open a diversified supply chain ensuring optimised costs and better flexibility."}
                        {serviceType === "experience" &&
                          "Our distinguished team with decades of collective experience, committed to delivering innovative solutions and strategic advantage."}
                        {serviceType === "end-to-end-management" &&
                          "Simplifying your global Supply chain. We take responsibility of ensuring that all logistics activities are seamlessly coordinated and integrated to facilitate efficient trade flows and new opportunities for your business."}
                      </p>
                    </div>
                  ) : (
                    <div style={mainSectionStyles}>
                      <div style={mainImageStyles}>
                        <img src={serviceImage} alt={formattedServiceType} style={{ width: "100%", height: "400px" }} />
                      </div>
                      <div style={mainTextStyles}>
                        <h3 style={{ color: "#345C84", fontSize: "2.5em", textTransform: "uppercase" }}>
                          {formattedServiceType}
                        </h3>
                        <p style={{ fontSize: "1.5rem", lineHeight: "1.5", color: "#75DAB4", fontWeight: "bold" }}>
                          {serviceType === "customisation" &&
                            "Whether you are sourcing a new customised product for your business or project, or components for your regular manufacturing or business, Shuvira team ensures solutions meeting your specific product requirements and key criteria."}
                          {serviceType === "global-partnerships" &&
                            "Our global network provides strong partners and potential alternatives for a strong supply chain management solution. At Shuvira, we aim to empower your business with global market knowledge, expertise, and open a diversified supply chain ensuring optimised costs and better flexibility."}
                          {serviceType === "experience" &&
                            "Our distinguished team with decades of collective experience, committed to delivering innovative solutions and strategic advantage."}
                          {serviceType === "end-to-end-management" &&
                            "Simplifying your global Supply chain. We take responsibility of ensuring that all logistics activities are seamlessly coordinated and integrated to facilitate efficient trade flows and new opportunities for your business."}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <ServiceDetailsItems serviceType={serviceType} />
          </div>
        </div>
        <SubscribArea />
      </div>
    </>
  );
}

export default ServiceDetails;
