import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function Breadcrumb({ pageTitle, pageName, backgroundImage }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <section
        className="breadcrumbs"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt">
                  <h1>{pageTitle}</h1>
                  <span>
                    <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                    <i className="bi bi-arrow-right" />
                    {pageName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Breadcrumb;
