import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Navigation,
  EffectFade,
  Pagination
} from "swiper";
SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination]);

function Testimonial() {
    const testimonialSlider = {
        loop: true,
        slidesPerView: 2,
        centeredSlides: true,
        duration: 5000,
        speed: 1000,
        autoplay: {
          delay: 3500,
          disableOnInteraction: true,
        },
        spaceBetween: 30,
        slideToClickedSlide: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 2,
            },
            1800: {
                slidesPerView: 2,
            }
        }
    }

    return (
      <>
        <section className="testimonial-area">
          <div className="container-fluid p-0">
            <div className="title-wrap">
              <div className="sec-title white">
                <h2>Our Commitment</h2>
                <p>To drive innovation and excellence in all we do, empowering our customers and communities to thrive. We are dedicated to creating ease of business solutions and fostering growth, 
                  with a vision to shape a better future for all.</p>
              </div>
            </div>
            <Swiper {...testimonialSlider} className="swiper testimonial-slider">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <div className="single-testimonial" style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="/img/reliability.jpg" alt="Reliability" style={{ width: '50%', height: '250px', marginRight: '20px' }} />
                    <div>
                      <h5>Reliability</h5>
                      <p>Process Optimization creates efficient workflows ensuring timely delivery and cost-effective solutions.</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="single-testimonial" style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="/img/flexibility.jpg" alt="Flexibility" style={{ width: '50%', height: '250px', marginRight: '20px' }} />
                    <div>
                      <h5>Flexibility</h5>
                      <p>We prioritize understanding and meeting your specific needs, delivering tailored solutions that drive your success.</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="single-testimonial" style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="/img/quality.jpg" alt="Quality Assurance" style={{ width: '50%', height: '250px', marginRight: '20px' }} />
                    <div>
                      <h5>Quality Assurance</h5>
                      <p>We adhere to stringent quality standards, ensuring that every product we source & produce meets quality standards.</p>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
            <div className="swiper-button-next" />
            <div className="swiper-button-prev" />
          </div>
        </section>  
      </>
    )
}

export default Testimonial
