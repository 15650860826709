import React from 'react';
import ProjectDetailsContent from './ProjectDetailsContent';

function ProjectDetailsWrap({ industryType }) {
  return (
    <div className="project-details sec-mar-top">
      <div className="container">

          <div className="col-lg-12">
            <ProjectDetailsContent industryType={industryType} />
          </div>

      </div>
    </div>
  );
}

export default ProjectDetailsWrap;
