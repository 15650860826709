import React from "react";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ProjectWrap from "./ProjectWrap";

function Project() {
  return (
    <>
      <div className="creasoft-wrap">
        <AnimateLine />
        <Breadcrumb 
          pageName="Industries" 
          pageTitle="Industries" 
          backgroundImage={process.env.PUBLIC_URL + "/img/technology1.png"} 
        />
        <ProjectWrap />
        <SubscribArea />
      </div>
    </>
  );
}

export default Project;
