import React from "react";
import AnimateLine from "../../common/AnimateLine";
import AboutArea from "../home/AboutArea";
import FeaturesArea from "../home/FeaturesArea";

import Testimonial from "../home/Testimonial";

import WhyChooseUs from "./WhyChooseUs";

function AboutWrap() {
  return (
    <>
      <div className="creasoft-wrap">
        <AnimateLine />
        <WhyChooseUs />
        <AboutArea image="/img/logo-dark.svg"/>
        <FeaturesArea/>
      </div>
    </>
  );
}

export default AboutWrap;
