import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ContactWrapper from "./ContactWrapper";
import AnimateLine from "../../common/AnimateLine";

function Contact() {
  return (
    <>
      <div className="creasoft-wrap">
        <AnimateLine />
        {/* Ensure the correct image path is used here */}
        <Breadcrumb 
          pageTitle="Contact us" 
          pageName="Contact us" 
          backgroundImage="/img/contactus.jpg" 
        />
        <ContactWrapper />
        <SubscribArea />
      </div>
    </>
  );
}

export default Contact;
