import React from 'react'
import AnimateLine from '../../common/AnimateLine'
import SubscribArea from '../../common/SubscribArea'
import ProjectWrap from '../project/ProjectWrap'
import AboutArea from './AboutArea'

import HeroArea from './HeroArea'
import ServiceArea from './ServiceArea'
import Testimonial from './Testimonial'

function HomePageOne() {
  return (
    <>
        <div className="creasoft-wrap">
            <AnimateLine/>
            <HeroArea/>
            <ServiceArea/>
            <AboutArea image="/img/logo.pn"/>
              <ProjectWrap/>
            <Testimonial/>
            <SubscribArea/>
        </div>
    </>
  )
}

export default HomePageOne