import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS

function ContactWrapper() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    designation: '',
    companyName: '',
    companyWebsite: '',
    companyAddress: '',
    message: '',
    termsAccepted: false,
    captchaInput: ''
  });

  const [formStatus, setFormStatus] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Generate CAPTCHA
  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let captchaText = '';
    for (let i = 0; i < 6; i++) {
      captchaText += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptcha(captchaText);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    if (!formData.name || !formData.email || !formData.phone || !formData.message) {
      setFormStatus('Please fill in all required fields.');
      return;
    }

    // Check if terms are accepted
    if (!formData.termsAccepted) {
      setFormStatus('You must accept the Terms of Use and Privacy Policy.');
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
      return;
    }

    // Display CAPTCHA Modal
    setShowCaptchaModal(true);
  };

  const handleCaptchaSubmit = () => {
    // Validate CAPTCHA
    if (formData.captchaInput !== captcha) {
      setFormStatus('CAPTCHA does not match. Please try again.');
      generateCaptcha(); // regenerate CAPTCHA if failed
      return;
    }

    // Send email using EmailJS after CAPTCHA is verified
    const serviceID = 'service_712a2fp'; // Your EmailJS Service ID
    const templateID = 'template_sbw7h3g'; // Your EmailJS Template ID (replace with actual ID)
    const userID = '7ZNmKDDhXSe1WBK5z'; // Your EmailJS User ID (replace with actual ID)

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setFormStatus('');
        setShowCaptchaModal(false); // Close CAPTCHA modal
        setShowSuccessModal(true); // Show success modal
        // Reset form data after success
        setFormData({
          name: '',
          email: '',
          phone: '',
          designation: '',
          companyName: '',
          companyWebsite: '',
          companyAddress: '',
          message: '',
          termsAccepted: false,
          captchaInput: ''
        });
        generateCaptcha(); // regenerate CAPTCHA after successful submission
      }, (error) => {
        console.log('FAILED...', error);
        setFormStatus('Failed to send message. Please try again later.');
      });
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const formStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px'
  };

  const inputStyle = {
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    padding: '10px 15px',
    marginBottom: '20px',
    fontSize: '16px',
    fontWeight: 'bold',
    width: '95%',
    transition: 'all 0.3s ease',
    outline: 'none'
  };

  const textareaStyle = {
    ...inputStyle,
    gridColumn: '1 / -1',
    height: '120px',
    resize: 'none'
  };

  const submitStyle = {
    backgroundColor: '#75DAB4',
    color: '#fff',
    border: 'none',
    borderRadius: '30px',
    padding: '12px 25px',
    cursor: 'pointer',
    fontSize: '16px',
    gridColumn: '1 / -1',
    width: '100%',
    transition: 'background-color 0.3s ease',
    outline: 'none'
  };

  const formHeaderStyle = {
    fontSize: '30px',
    color: '#75DAB4',
    marginBottom: '30px',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bold' 
  };

  const checkboxContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  };
  
  const checkboxStyle = {
    marginRight: '8px',
    width: '26px',
    height: '20px',
  };
  
  const linkStyle = {
    color: '#007bff',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginLeft: '5px'
  };

  const captchaContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    gridColumn: '1 / -1'
  };

  const captchaTextStyle = {
    fontSize: '26px',
    fontWeight: 'bold',
    backgroundColor: '#e9e9e9',
    padding: '10px',
    borderRadius: '5px',
    marginRight: '10px',
    letterSpacing: '2px',
    textDecoration: 'line-through',
    color: '#000',
    display: 'inline-block',
    fontFamily: 'monospace'
  };

  const captchaInputStyle = {
    ...inputStyle,
    width: '60%'
  };

  const modalStyle = {
    display: showCaptchaModal ? 'block' : 'none',
    position: 'fixed',
    zIndex: '999',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)',
    width: '90%', // Ensure width is responsive
    maxWidth: '400px', // Limit max width
  };

  const overlayStyle = {
    display: showCaptchaModal || showSuccessModal ? 'block' : 'none',
    position: 'fixed',
    zIndex: '998',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  };

  const successModalStyle = {
    display: showSuccessModal ? 'block' : 'none',
    position: 'fixed',
    zIndex: '1000',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
    width: '90%', // Ensure width is responsive
    maxWidth: '400px', // Limit max width
  };

  return (
    <>
      <section className="contact-area sec-mar">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <div className="contact-left">
                <div className="sec-title layout2">
                  <h2>Contact us if you have more questions.</h2>
                </div>
                <ul className="social-follow">
                  <li><a href="https://www.facebook.com/"><i className="fab fa-facebook-f" /></a></li>
                  <li><a href="https://www.twitter.com/"><i className="fab fa-twitter" /></a></li>
                  <li><a href="https://www.pinterest.com/"><i className="fab fa-pinterest-p" /></a></li>
                  <li><a href="https://www.instagram.com/"><i className="fab fa-instagram" /></a></li>
                </ul>
                <div className="informations">
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="info">
                      <h3>Location</h3>
                      <p>7 Siglap Road , Singapore - 448909</p>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="info">
                      <h3>Phone</h3>
                      <a href="#">65-62413991</a>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="info">
                      <h3>Email</h3>
                      <a href="#">biz_admin@shuvira.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1994.389513256367!2d103.92390975634207!3d1.30779499966859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da22a2a5aef85d%3A0x2ce128ee5c72bd21!2s7%20Siglap%20Rd%2C%20Mandarin%20Gardens%20Lobby%20S%2C%20Singapore%20448909!5e0!3m2!1sen!2sin!4v1724997464809!5m2!1sen!2sin"
                    frameBorder="0" 
                    style={{ border: 0 }} 
                    allowFullScreen="" 
                    aria-hidden="false" 
                    tabIndex="0">
                  </iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="getin-touch">
            <div className="row">
              <div className="col-md-6 col-lg-7">
                <div className="contact-form wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                  <h3 style={formHeaderStyle}>Have Any Questions</h3>
                  <form onSubmit={handleSubmit} style={formStyle}>
                    <input 
                      type="text" 
                      name="name" 
                      value={formData.name} 
                      onChange={handleChange} 
                      placeholder="Enter your name" 
                      required 
                      style={inputStyle} 
                    />
                    <input 
                      type="email" 
                      name="email" 
                      value={formData.email} 
                      onChange={handleChange} 
                      placeholder="Enter your email" 
                      required 
                      style={inputStyle} 
                    />
                    <input 
                      type="text" 
                      name="phone" 
                      value={formData.phone} 
                      onChange={handleChange} 
                      placeholder="Enter your phone" 
                      required 
                      style={inputStyle} 
                    />
                    <input 
                      type="text" 
                      name="designation" 
                      value={formData.designation} 
                      onChange={handleChange} 
                      placeholder="Designation" 
                      style={inputStyle} 
                    />
                    <input 
                      type="text" 
                      name="companyName" 
                      value={formData.companyName} 
                      onChange={handleChange} 
                      placeholder="Company Name" 
                      style={inputStyle} 
                    />
                    <input 
                      type="url" 
                      name="companyWebsite" 
                      value={formData.companyWebsite} 
                      onChange={handleChange} 
                      placeholder="Company Website" 
                      style={inputStyle} 
                    />
                    <input 
                      type="text" 
                      name="companyAddress" 
                      value={formData.companyAddress} 
                      onChange={handleChange} 
                      placeholder="Company Address" 
                      style={inputStyle} 
                    />
                    <textarea 
                      name="message" 
                      value={formData.message} 
                      onChange={handleChange} 
                      placeholder="Your message" 
                      required 
                      style={textareaStyle} 
                    ></textarea>

                    <div style={checkboxContainerStyle}>
                      <input 
                        type="checkbox" 
                        name="termsAccepted" 
                        checked={formData.termsAccepted} 
                        onChange={handleChange} 
                        style={checkboxStyle} 
                      />
                      <label>
                        I agree that I've gone through the 
                        <span style={linkStyle} onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" })}>Terms of Use </span> 
                        and 
                        <span style={linkStyle} onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" })}>Privacy Policy</span>.
                      </label>
                    </div>

                    <input 
                      type="submit" 
                      value="Send Message" 
                      style={submitStyle}
                      onMouseOver={(e) => e.target.style.backgroundColor = '#66c4a2'}
                      onMouseOut={(e) => e.target.style.backgroundColor = '#75DAB4'}
                    />
                  </form>
                  {formStatus && <p>{formStatus}</p>}
                </div>
              </div>
              <div className="col-md-6 col-lg-5 wow animate fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                <div className="call-banner">
                  <img src={process.env.PUBLIC_URL + "/img/contact.jpg"} alt="images" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CAPTCHA Modal */}
      <div style={overlayStyle}></div>
      <div style={modalStyle}>
        <h3>Verify CAPTCHA</h3>
        <div style={captchaContainerStyle}>
          <span style={captchaTextStyle}>{captcha.split('').map((char, index) => (
            <span key={index} style={{ 
              transform: `rotate(${Math.random() * 10 - 5}deg)`, 
              display: 'inline-block', 
              marginRight: '2px' 
            }}>
              {char}
            </span>
          ))}</span>
          <input 
            type="text" 
            name="captchaInput" 
            value={formData.captchaInput} 
            onChange={handleChange} 
            placeholder="Enter CAPTCHA" 
            required 
            style={captchaInputStyle} 
          />
        </div>
        <button onClick={handleCaptchaSubmit} style={submitStyle}>Verify and Submit</button>
      </div>

      {/* Success Modal */}
      <div style={successModalStyle}>
        <h3>Success</h3>
        <p>Thankyou for contacting us. We will revert to you soon!</p>
        <button onClick={closeSuccessModal} style={submitStyle}>Close</button>
      </div>

      <style jsx="true">{`
        @media (max-width: 768px) {
          .contact-area {
            padding: 20px; /* Add padding to prevent content from touching the edges */
          }

          .captcha-container {
            flex-direction: column; /* Stack the CAPTCHA text and input on mobile */
          }

          .contact-form {
            padding: 10px; /* Reduce padding on smaller screens */
          }

          .contact-form input, .contact-form textarea {
            width: 100%; /* Make inputs full width on mobile */
          }

          .captcha-input-style {
            width: 80%; /* Adjust CAPTCHA input size on mobile */
          }

          .modalStyle {
            width: 90%; /* Set modal width to a more comfortable mobile size */
          }

          .captchaContainerStyle {
            flex-wrap: wrap; /* Wrap the CAPTCHA and input field to stack on smaller screens */
          }
        }
      `}</style>
    </>
  );
}

export default ContactWrapper;
