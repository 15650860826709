import React from 'react';
import { Link } from 'react-router-dom';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';

SwiperCore.use([Navigation, Autoplay, Pagination]);

function Testimonial() {
    const testimonialSlider = {
        loop: true,
        slidesPerView: 2,
        centeredSlides: true,
        duration: 5000,
        speed: 1000,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        spaceBetween: 30,
        slideToClickedSlide: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 2,
            },
            1800: {
                slidesPerView: 2,
            }
        }
    };

    return (
      <>
        <section className="testimonial-area">
          <div className="container-fluid p-0">
            <div className="title-wrap">
              <div className="sec-title white">
                <h2>OUR COMMITMENT</h2>
                <p>To drive innovation and excellence in all we do, empowering our customers and communities to thrive. We are dedicated to <b>creating ease of business solutions and fostering growth</b>, with a vision to shape a better future for all.</p>
              </div>
            </div>
            <Swiper {...testimonialSlider} className="swiper testimonial-slider">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <div className="single-testimonial">
                    <img src="/img/reliability.jpg" alt="Reliability" />
                    <div>
                      <h5>Reliability</h5>
                      <p>Process Optimization creates efficient workflows ensuring timely delivery and cost-effective solutions.</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="single-testimonial">
                    <img src="/img/flexibility.jpg" alt="Flexibility" />
                    <div>
                      <h5>Flexibility</h5>
                      <p>We prioritize understanding and meeting your specific needs, delivering tailored solutions that drive your success.</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="single-testimonial">
                    <img src="/img/quality.jpg" alt="Quality Assurance" />
                    <div>
                      <h5>Quality Assurance</h5>
                      <p>We adhere to stringent quality standards, ensuring that every product we source & produce meets quality standards.</p>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
            <div className="swiper-button-next" />
            <div className="swiper-button-prev" />
          </div>
        </section>  

        <style jsx="true">{`
          /* Styles adjusted for mobile and tablet */
          @media (max-width: 1399px) {
            .single-testimonial {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              padding: 20px;
            }

            .single-testimonial img {
              width: auto;
              height: 250px; // Consistent height
              object-fit: cover;
              margin-bottom: 20px;
            }

            .single-testimonial div {
              padding: 0 10px;
            }

            .swiper-slide {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          /* Desktop specific styles remain unchanged */
          @media (min-width: 1400px) {
            .single-testimonial {
              display: flex;
              align-items: center;
              justify-content: start;
            }

            .single-testimonial img {
              width: 50%;
              height: 250px;
              margin-right: 20px;
            }
          }

          .testimonial-area {
            background-color: #345c84;
            padding: 100px 0;
          }

          .swiper-button-next, .swiper-button-prev {
            top: 50%;
          }

          .swiper-button-next:after, .swiper-button-prev:after {
            font-size: 20px;
            height: 40px;
            min-width: 40px;
            color: #e4e4e4;
            background-color: #2a2a2a;
            border: 1px solid #2a2a2a;
            text-align: center;
            line-height: 40px;
            border-radius: 50%;
            transition: all 0.5s ease-out;
          }

          .swiper-button-next:hover:after, .swiper-button-prev:hover:after {
            color: #000;
            background-color: #75dab4;
          }

          .swiper-button-next {
            right: 10px;
          }

          .swiper-button-prev {
            left: 10px;
          }

          .swiper.testimonial-slider .swiper-slide {
            opacity: 0.6;
          }

          .swiper.testimonial-slider .swiper-slide.swiper-slide-active {
            opacity: 1;
          }
        `}</style>
      </>
    );
}

export default Testimonial;
