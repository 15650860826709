import React from "react";
import { Link } from "react-router-dom"; // Import Link for React Router
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import "swiper/css"; // Import Swiper styles
import "swiper/css/navigation"; // Import Swiper navigation styles

SwiperCore.use([Navigation, Autoplay]);

function ServiceCardWrap(props) {
  const serviceSlider = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <>
      <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <h2
                style={{
                  color: "#345c84", // Same color as "Why Shuvira"
                  fontWeight: "bold", // Make the heading big and bold
                  fontSize: "46px", // Adjust the font size as needed
                  marginBottom: "20px", // Add some space below the heading
                  textAlign: "center", // Center the heading
                }}
              >
                EXPERTISE
              </h2>
              <p
                style={{
                  fontSize: "22px",
                  lineHeight: "1.6",
                  textAlign: "center",
                  maxWidth: "800px",
                  margin: "0 auto",
                }}
              >
                With a strong commitment to{" "}
                <b>quality, innovation, and personalized service</b>, we have
                established ourselves as a reliable partner for businesses
                seeking <b>customized solutions.</b> Partner with Shuvira for
                your global sourcing needs, enhancing efficiency and driving
                savings throughout your supply chain.
              </p>
            </div>
          </div>

          <Swiper {...serviceSlider} className="swiper services-slider">
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>01</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/service-icon-1.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Customisation</h4>
                  <p>
                    Providing bespoke product solutions tailored to meet the
                    unique needs of each client, Shuvira exemplifies excellence
                    in customization.
                  </p>
                  <div className="read-btn">
                    {/* Replace <a> with <Link> */}
                    <Link to={`${process.env.PUBLIC_URL}/service-details/customisation`}>
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>02</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/service-icon-2.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Global Partnerships</h4>
                  <p>
                    Leveraging a robust network of international partnerships to
                    drive innovation and deliver unparalleled value across
                    global markets.
                  </p>
                  <div className="read-btn">
                    {/* Replace <a> with <Link> */}
                    <Link to={`${process.env.PUBLIC_URL}/service-details/global-partnerships`}>
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>03</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/service-icon-3.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Experience</h4>
                  <p>
                    Expertly Driven by a Seasoned Team: Experience and Insight
                    That Propel our Success.
                  </p>
                  <div className="read-btn">
                    {/* Replace <a> with <Link> */}
                    <Link to={`${process.env.PUBLIC_URL}/service-details/experience`}>
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="800ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>04</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/service-icon-4.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>End to End Management</h4>
                  <p>
                    Providing comprehensive supply chain management solutions
                    that seamlessly integrate every phase from sourcing to
                    delivery, ensuring efficiency and reliability throughout.
                  </p>
                  <div className="read-btn">
                    {/* Replace <a> with <Link> */}
                    <Link to={`${process.env.PUBLIC_URL}/service-details/end-to-end-management`}>
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>

          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default ServiceCardWrap;
