import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ProjectDetailsWrap from "./ProjectDetailsWrap";

function ProjectDetails() {
  const { industryType } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  return (
    <>
      <div className="creasoft-wrap">
        <AnimateLine />
        <Breadcrumb 
          pageName={`Industries - ${industryType}`} 
          pageTitle={`Industries - ${industryType}`} 
          backgroundImage={process.env.PUBLIC_URL + "/img/technology1.png"} 
        />
        <ProjectDetailsWrap industryType={industryType} />
        <SubscribArea />
      </div>
    </>
  );
}

export default ProjectDetails;
