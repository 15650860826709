import React from 'react'

function WhyChooseUs() {
  return (
    <>
      <section className="why-choose sec-mar wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                <div className="choose-banner1">
                  <img src={process.env.PUBLIC_URL + "/img/aboutus (3).jpg"} alt="why-choose images" />

                </div>
                <div className="choose-banner2">
                  <img src={process.env.PUBLIC_URL + "/img/aboutus (2).png"} alt="why-choose images" />
                  <img src={process.env.PUBLIC_URL + "/img/aboutus (3).png"} alt="why-choose images" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <h2>Why Choose Us</h2>
                </div>
              
                <p><b>Our mission</b> is to drive business success through a streamlined and agile procurement process, delivering significant cost savings and ensuring transparent end-to-end supply chain visibility. 
                <br></br><br></br>By leveraging our expertise and advanced technologies,<b> we provide actionable insights and strategic solutions that enhance efficiency and competitiveness. </b>
                </p><br></br>
                <p>As we collaborate closely with you, we are committed to fostering a partnership that not only meets but exceeds your expectations, providing a substantial competitive advantage as we grow and succeed together.</p><br></br>
                <p>Our experienced and visionary team excels in the strategic sourcing and ensures the highest quality and cost-effectiveness, making us your ideal partner for reliable and efficient procurement. 
                  <br></br><br></br> <b>Trust us to transform your vision into tangible results on a global scale.</b>
                 </p>
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default WhyChooseUs