import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import FeaturesArea from "./FeaturesArea"; // Import FeaturesArea

SwiperCore.use([Navigation, Autoplay]);

function ServiceArea(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const serviceSlider = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1, // Adjust for mobile screens
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <>
      <section className={`${"why-shuvira-area sec-mar"} ${props.layoutClass}`}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <h2
                style={{
                  color: "#345c84",
                  fontWeight: "bold",
                  fontSize: "46px",
                  marginBottom: "20px",
                  textAlign: "center",
                  wordBreak: "break-word", // Handle long words in mobile view
                }}
              >
                WHY SHUVIRA
              </h2>
              <p
                style={{
                  fontSize: "22px",
                  maxWidth: "100%",
                  height: "auto", // Adjust height dynamically
                  lineHeight: "1.6",
                  wordBreak: "break-word",
                  textAlign: "justify", // Better readability on mobile
                  textAlign: "center",
                }}
              >
                At our core, <b>we empower businesses to thrive by delivering an agile procurement process that drives substantial cost savings.</b> Our commitment to transparency ensures end-to-end supply chain visibility, giving you the control and insight needed for strategic decision-making. By choosing us, you gain a trusted partner dedicated to enhancing your competitive advantage.
                <br />
                <b>Together, we’ll foster growth, innovation, and success, making your business stronger and more resilient in the marketplace.</b>
              </p>

              {/* Contact Us Button */}
              <div style={{ textAlign: "center", marginTop: "80px" }}>
                <Link
                  to={`${process.env.PUBLIC_URL}/contact`}
                  onClick={scrollTop}
                  style={{
                    backgroundColor: "#345c84",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    textDecoration: "none",
                    display: "inline-block",
                  }}
                >
                  Reach out to us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Include FeaturesArea */}
      <FeaturesArea />

      <section className={`${"services-area sec-mar"} ${props.layoutClass}`}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <h2
                style={{
                  color: "#345c84",
                  fontWeight: "bold",
                  fontSize: "46px",
                  marginBottom: "20px",
                }}
              >
                EXPERTISE
              </h2>
              <p
                style={{
                  fontSize: "22px",
                  lineHeight: "1.7",
                }}
              >
                At the heart of our business is an unwavering commitment to
                <b> quality, innovation, and personalized service.</b> We have
                established ourselves as a reliable partner for businesses
                seeking <b> customized solutions to precision sourcing standards.</b> Partner with Shuvira for your global sourcing needs, enhancing efficiency and driving savings throughout your supply chain.
              </p>
            </div>
          </div>

          <Swiper {...serviceSlider} className="swiper services-slider">
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>01</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/service-icon-1.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Customisation</h4>
                  <p>
                    Providing bespoke product solutions tailored to meet the
                    unique needs of each client, exemplifies excellence in
                    customization.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details/customisation`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>02</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/service-icon-2.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Global Partnerships</h4>
                  <p>
                    Leveraging a robust network of international partnerships to
                    drive innovation and deliver unparalleled value across
                    global markets.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details/global-partnerships`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>03</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/service-icon-3.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>Experience</h4>
                  <p>
                    Expertly Driven by a Seasoned Team: Experience and Insight
                    That Propel our Success.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details/experience`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <span>04</span>
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/img/service-icon-4.png"}
                      alt="service-icon"
                    />
                  </div>
                  <h4>End to End Management</h4>
                  <p>
                    Providing comprehensive supply chain management solutions,
                    ensuring efficiency and reliability throughout.
                  </p>
                  <div className="read-btn">
                    <Link
                      to={`${process.env.PUBLIC_URL}/service-details/end-to-end-management`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>

      <style jsx="true">{`
        /* Media queries for responsive design */
        @media only screen and (max-width: 768px) {
          .why-shuvira-area h2 {
            font-size: 32px; /* Smaller heading on mobile */
          }

          .why-shuvira-area p {
            font-size: 18px; /* Smaller text size for mobile readability */
          }

          .services-area h2 {
            font-size: 32px; /* Adjust expertise heading */
          }

          .services-area p {
            font-size: 18px; /* Adjust paragraph size for mobile */
          }

          .swiper-wrapper {
            padding: 10px; /* Add padding for better spacing on mobile */
          }
        }
      `}</style>
    </>
  );
}

export default ServiceArea;
